import React from 'react'
import { Box } from '@mui/system'
import background from './images/Home_Background.jpg'
import { Typography } from '@mui/material'

function Home() {
  return (
    <div id="Home">
       <Box 
        height="100vh" 
        justifyContent="center" 
        alignItems="center" 
        display="flex" 
        flexDirection="column" 
        style = {{backgroundImage: `url(${background})`}}
      >
      <Typography 
          variant='h2'
          align="center" 
          style = {{ color: "white"}}
        >
          Hey! My name is Ethan Evans, I'm a Full-Stack Software Engineer.
          <br/>
          <br/>
          Let's Build Something.
      </Typography>
       </Box>
    </div>
  )
}

export default Home
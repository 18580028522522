import React from 'react'
import { Box } from '@mui/system'
import { IconButton, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'
import { Card } from '@mui/material'
import { CardActions } from '@mui/material'
import { CardContent } from '@mui/material'
import AppStoreIcon from './images/app-store.png'
import GooglePlayStoreIcon from './images/google-play-store.png'
import { Avatar } from '@mui/material'
import PointTrackScreenshot from './images/PointTrack.png'

// project-title
// project-body
// techonlogy used
// image
// Link to Demo Video
// Link to App Store
// Link to PlayStores

function Projects() {
  return (
    <div id="Projects">
      <Box 
        height="100vh" 
        display="flex"
        style={{ backgroundColor: "#636363"}}
        sx={{ p: 8 }}
      >
        <h1></h1>
      </Box>
    </div>
  )
}

export default Projects
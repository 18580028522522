import React , { useRef, useState } from 'react';
import { Box } from '@mui/system'
import { Card, CardContent, Typography } from '@mui/material'
import { TextField } from '@mui/material'
import { Grid } from '@mui/material'
import { Button } from '@mui/material'

function Contact() {
  const form = useRef();
  const [email, setEmail] = useState(""); 
  const [subject, setSubject] = useState(""); 
  const [message, setMessage] = useState(""); 

  const sendEmail = (e) => {
    console.log(e)
    console.log(email)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          "from": email,
          "subject": subject,
          "message": message
        })
    };
    fetch('https://kvs0k0kl7i.execute-api.us-east-1.amazonaws.com/Stage/contact', requestOptions)
  };

  return (
    <div id = "Contact">
      <Box 
        height="100vh" 
        justifyContent="center" 
        alignItems="center" 
        display="flex" 
        flexDirection="column"
        style={{ backgroundColor: "#636363"}}
        sx={{ p: 8 }}
      >
      <Grid container spacing={2} rowSpacing={1}>
        <Grid item xs={6} direction="column" rowSpacing={1}>
          <Typography 
              variant='h2'
              align="center" 
              color={'#FFFFFF'}
            >
              Let's Connect
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color={'#FFFFFF'}
          >
            ethan.d.evans9@gmail.com
          </Typography>
        </Grid>
        <Grid container item xs={6} direction="column" rowSpacing={1}>
          <form  onSubmit={sendEmail}>
            <TextField
              id="email-input"
              name="from_email"
              label="Email"
              type="text"
              onChange={(e) => { 
                setEmail(e.target.value); 
              }} 
              sx={{ 
                input: { color: 'white' },
                 
                "& .MuiFormLabel-root": {
                  color: 'white'
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: 'white'
                },
                fieldset: { borderColor: "white" },
                margin: 1,
              }}
            />
            <br/>
            <TextField
              id="subject-input"
              name="from_subject"
              label="Subject"
              type="Subject"
              onChange={(e) => { 
                setSubject(e.target.value); 
              }} 
              sx={{ 
                input: { color: 'white' }, 
                "& .MuiFormLabel-root": {
                  color: 'white'
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: 'white'
                },
                fieldset: { borderColor: "white" },
                margin: 1
              }}
            />
            <br/>
            <TextField
              id="message-input"
              name="message"
              label="Message"
              type="text"
              multiline
              onChange={(e) => { 
                setMessage(e.target.value); 
              }} 
              sx={{ 
                input: { color: 'white' }, 
                "& .MuiFormLabel-root": {
                  color: 'white'
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: 'white'
                },
                "& label.Mui-focused": {
                  color: 'white'
                },
                fieldset: { borderColor: "white" },
                margin: 1
              }}
            />
            <br/>
            <Button variant='contained' type="submit" sx={{margin: 1}} justifyContent="center">
              Send
            </Button>
          </form>
        </Grid>
      </Grid>
      </Box>
    </div>
  )
}

export default Contact
import About from './about/About';
import Projects from './projects/Projects';
import Blog from './blog/Blog';
import Contact from './contact/Contact'
import Header from './header/Header';
import Home from './home/Home';
import { Routes, Route, BrowserRouter } from 'react-router-dom'; 
import BlogPost from './blog/blog_post/BlogPost';


function App() {
  return (
      // <BrowserRouter>
      //   <div class="App">
      //     <Header/>
      //     <Home/>
      //     <About/>
      //     {/* <Projects/> */}
      //     <Blog/>
      //     <Contact/>
      //     {/* <Routes>
            // <Route path="/" element={<Home/>}/>
            // <Route path="/about" element={<About/>}/>
            // <Route path="/projects" element={<Projects/>}/>
            // <Route path="/blog" element={<Blog/>}/>
            // <Route path="/blog/:slug" element={<BlogPost/>}/>
            // <Route path="/contact" element={<Contact/>}/>
      //     </Routes> */}
      //   </div>



      // </BrowserRouter>

      <BrowserRouter>
        {/* <Header/>
        <Home/>
        <About/>
        {/* <Projects/> */}
        {/* <Blog/>
        <Contact/> */} 
        <Header/>
        <Routes>
        <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/projects" element={<Projects/>}/>
            <Route path="/blogs" element={<Blog/>}/>
            <Route path="/blogs/:blogId" element={<BlogPost/>}/>
            <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </BrowserRouter>
  );
}

export default App;

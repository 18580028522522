import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { Box } from '@mui/system'
import { Typography, Button } from '@mui/material'
import ReactMarkdown from "react-markdown";

function BlogPost() {
  const { blogId } = useParams();
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    fetch(`https://kvs0k0kl7i.execute-api.us-east-1.amazonaws.com/Stage/blogs/${blogId}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        data.published_on = new Date(Date.parse(data.published_on)).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
        setBlog(data);
      });
  }, []);

  return (
    <div id="BlogPost">
      <Box 
        height="100vh" 
        style={{ backgroundColor: "#636363"}}
      >
        <Typography 
          variant='h3'
          align="center" 
          color="white"
          pt={5}
        >
          {blog.title}
        </Typography>
        <Typography 
          variant='h6'
          color="white"
          align="center"
        >
          By: {blog.author} &#9679; Published On: {blog.published_on }
        </Typography>
        <Typography
          color="white"
          px={30}
        >
          <ReactMarkdown>
            {blog.content}
          </ReactMarkdown>
        </Typography>
      </Box>
    </div>
  )
}

export default BlogPost
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import ProfileImage from './images/Ethan_Profile.jpg'
import { Link as ScrollLink } from "react-scroll/modules"
import { Scroll } from 'react-scroll/modules/mixins/Helpers';

// TODO add 'Projects' back
const pages = ['About', 'Blogs', 'Contact'];

function Header() {

  return (
    <AppBar position="sticky" style = {{ background: "grey" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            &#60;ECODE/&#62;
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            
            { /* todo: figure how to do sap with nested routes{pages.map((page) => (
              <Button
                key={page}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <ScrollLink activeClass='active' to={page} smooth="true" offset={-50} duration={500}> 
                    {page}
                </ScrollLink>
              </Button>
            ))} */}
            {pages.map((page) => (
              <Button
                key={page}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Link to={page.toLowerCase()}>{page}</Link>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={() => window.open('https://twitter.com/evans9_d')}>
              <TwitterIcon style = {{ color: "white" }}/>
            </IconButton>
            <IconButton onClick={() => window.open('https://www.linkedin.com/in/ethan-evans-853752123/')}>
              <LinkedInIcon style = {{ color: "white" }}/>
            </IconButton>
            <IconButton onClick={() => window.open('https://gitlab.com/ethan.d.evans9')}>
              <GitHubIcon style = {{ color: "white" }}/>
            </IconButton>
            <IconButton onClick={() => window.location.replace('/about')}>
              <Avatar alt="Ethan Evans" src={ProfileImage} />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
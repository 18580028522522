import React, { useEffect, useState } from "react";
import { Box } from '@mui/system'
import { Typography, Button } from '@mui/material'
import { Link } from "react-router-dom";
import {useQuery} from 'react-query';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

function Blog() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetch("https://kvs0k0kl7i.execute-api.us-east-1.amazonaws.com/Stage/blogs")
      .then(response => {
        return response.json()
      })
      .then(data => {
        console.log(data["blogs"])
        setBlogs(data["blogs"])
      });
  }, []);

  return (
    <div id="Blog">
       <Box 
        height="100vh" 
        style={{ backgroundColor: "#636363"}}
      >
      <Typography 
          variant='h3'
          align="center" 
          color="white"
          pt={5}
        >
          Blogs
      </Typography>
      <div>
      <Grid container spacing={2} padding={3} direction="column" alignItems="center">
        {blogs.map((blog) => (
            <Grid id = {blog.id} item>
                <Card sx={{ minWidth: 500 }}>
                <CardContent>
                    <Typography variant='h5' gutterBottom align="center" >
                      {blog.title}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary" align="center">
                      By {blog.author}
                    </Typography>
                    <Typography variant="body1">
                      {blog.content.substring(0, 250)}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small">
                      <Link to={`./${blog.id}`}>Read more</Link>
                    </Button>
                </CardActions>
              </Card>
          </Grid>
        ))}
      </Grid>
      </div>
      </Box>
    </div>
  )
}

export default Blog
import React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import resume from '../../src/Documents/Ethan_Evans_SE_Resume.pdf'

function About() {
  return (
    <div id="About">
       <Box 
        height="100vh" 
        alignItems="center" 
        display="flex" 
        flexDirection="column" 
        style={{ backgroundColor: "#636363"}}
      >
      <Typography 
          variant='h3'
          align="center"
          color="white"
          pt={5}
        >
          About Me (Ethan Evans)
      </Typography>
      <Typography
        pt={2}
        pl={10}
        pr={10}
        color="white"
      >
        I am full-stack engineer located in Kalispell, Montana. 
        Tech has always been a large part of my life and something that keeps me fascinated to this day.
        In 2021 I graduated from Iowa State with a Bachelors in Computer Engineering. 
        I have a passion for building web applications and mobile apps that focus on areas that interest me.
        Those areas are making consumers lives easier, self-help, and the outdoors.
        <br/><br/>
        My techonology stack I have worked on both proffesionally and through personal projects include:<br/>
        <strong>Frontend Frameworks:</strong> Angular, React, Android, Swift, Boostrap, Tailwind, MaterialUi, Santity<br/>
        <strong>Backend Frameworks:</strong> Springboot, MongoDb, Postgres, Firebase<br/>
        <strong>Dev-Ops:</strong>AWS, GCP, Terraform, Jenkins, Gitlab CI/CD<br/>
        <strong>Programming Langauges:</strong>Java, Python, Swift, Kotlin, C, Javascript/Typescript<br/>
        <br/>
        When I am not working on projects I spend my time doing outdoor activies, podcasting, writing/reading, and looking for the next great adventure.
        <br/>
      </Typography>
      <Typography
        variant='h3'
        align='center'
        color="white"
        pt={2}
      >
        Experience
      </Typography>
      <Typography
        pt={2}
        pl={10}
        pr={10}
        color="white"
      >
        I am currently employed as a full-stack software engineer at Kingland where we focus on enterpise applications for companies in the accounting and finance sector.
        My responsiblity deals with implementing and fixing production defects, performing devops work, communicating with clients, and supporting techincal questions.
      </Typography>
      <Typography
        variant='h3'
        align='center'
        color="white"
        pt={2}
      >
        Professional Documents
      </Typography>
      <Typography
        color="white"
        pt={2}
      >
        <a href={resume} download>
          Download my resume, <strong>Ethan Evans Resume</strong>
        </a>
      </Typography>
       </Box>
    </div>
  )
}

export default About